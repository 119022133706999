import { Dispatch } from 'react'

import Obstruct from '@eaze/obstruction'
import schedule from '@eaze/schedule'
import isEmptyObject from 'is-empty-object'

import { getActiveCart } from '@/redux/cart/selectors'
import postClientSideAddress, { getClientSideRules } from '@bff/handlers/geolocality/client'
import { checkResidentialDeliveryOnly } from '@bff/handlers/geolocality/logic'
import { track } from '@helpers/analytics'
import { DEFAULT_MENU_SLUG, MENU_SLUG } from '@helpers/constants'
import { locationHasMenu } from '@helpers/location'
import { apiV2 } from 'api'
import { ErrorResponse } from 'bff/models/bff/menu'
import {AddressResponse, AddressObj} from 'bff/models/og/addresses'
import { Location } from 'bff/models/og/places'
import { formatSuggestion } from 'components/autocomplete/Suggestion'
import errHandler from 'helpers/error-handler'
import promisify from 'helpers/pify'
import { setAlertTypeVisibility } from 'redux/alert/actions'
import { alertTypes } from 'redux/alert/config/types'
import { isLoggedIn } from 'redux/auth/selectors'
import { fetchCart } from 'redux/cart/actions'
import checkoutAlertTypes from 'redux/checkout/actionTypes'
import { setCookie } from 'redux/cookies/actions'
import { addressLoaded, addressLoading } from 'redux/loading/actions'
import {
  isAddressExact,
  removePreviousAddress as removePreviousAddressLocation,
  setActiveAddress,
  setActiveDepot as setActiveDepotLocation,
  setRecentAddress as setRecentAddressLocation,
  savePotentialAddress,
  setAddress,
  setIdScanRequired,
  setUsaIdOnly
} from 'redux/location/actions'
import { getActiveDepot, getActiveLocation, getInSampleLocation } from 'redux/location/selectors'
import { getMenuEnabled } from 'redux/menu/selectors'

import { setResidentialOnlyError } from '../addressModal/actions'

const fetchLocation = apiV2.getPlaces
const fetchIdGeoRegs = apiV2.getIdGeoRegs

type DepotData = {
  adultUse: boolean
  cityCode: string
  cityName: string
  countryCode: string
  depots: []
  fullAddress: string
  id: string
  isAreaSupported: true
  isCalifornia: true
  isStreetAddr: true
  location: object
  menus: []
  previousOrder: boolean
  shortAddress: string
  state: string
  title: string
  zipCode: string
}

type DepotObstruct = {
  deliveryType: string
  dispensary: object
  id: number
  minimumOrderAmount: number
  payment: []
  primaryMenu: string
  schedule: []
}

export function setActiveLocation(address) {
  return (dispatch) => {
    dispatch(setActiveAddress(address))
  }
}

export function setActiveDepot(availableDepots) {
  return (dispatch) => {
    availableDepots &&
      availableDepots.forEach((depot, index) => {
        if (DEFAULT_MENU_SLUG === depot.primaryMenu) {
          dispatch(setActiveDepotLocation(availableDepots[index]))
        }
      })
  }
}

export function setRecentAddress(address) {
  address.icon = '/static/icons/recent.svg'

  return (dispatch) => dispatch(setRecentAddressLocation(address))
}

export function removePreviousAddress() {
  return (dispatch) => dispatch(removePreviousAddressLocation())
}

export async function fetchLocationByZip(zipCode) {
  try {
    const { data } = await apiV2.places({ query: zipCode, zipCode: true })
    return data
  } catch (err) {
    return err
  }
}

/**
 * locationFetcher returns a promise for fetching the final metadata for the place a user selects
 * from the AutoComplete.
 */
export async function locationFetcher(placeId) {
  const parseSuggestion = Obstruct({
    adultUse: 'adultUse',
    business: ['types', establishment],
    california: 'isCalifornia',
    city: 'cityName',
    coordinates: 'location',
    deliveryType: 'deliveryType',
    depot: Obstruct.parent(depot),
    availableDepots: Obstruct.parent(formatDepots),
    exact: 'isStreetAddr',
    id: true,
    menus: 'menus',
    ordered: 'previousOrder',
    state: 'state',
    street: 'shortAddress',
    supported: 'isAreaSupported',
    title: true,
    types: empty,
    zip: 'zipCode'
  })

  const ObstructDepot = Obstruct({
    dispensary: Obstruct.parent({
      id: 'dispensaryId',
      adultUse: 'adultUse'
    }),
    deliveryType: 'deliveryType',
    id: true,
    minimumOrderAmount: true,
    depotPaymentSettings: 'depotPaymentSettings',
    payment: 'enabledPaymentMethods',
    primaryMenu: true,
    schedule: ['workTimePeriods', schedule.parse]
  })

  function depot(data) {
    let index = 0
    const depotMenu = MENU_SLUG
    if (data && data.depots && data.depots.length > 1) {
      data.depots.forEach((depot, i) => {
        if (depot.primaryMenu === depotMenu) {
          index = i
        }
      })
    }
    return data && data.depots && data.depots[index] ? ObstructDepot(data.depots[index]) : null
  }

  function formatDepots(data) {
    const arr = []
    if (data && data.depots) {
      data.depots.forEach((depot) => {
        const depotData = ObstructDepot(depot)
        arr.push(depotData)
      })
    }
    return arr
  }

  function empty(data) {
    return data || []
  }

  function establishment(types) {
    if (!types) return
    return types.indexOf('establishment') >= 0
  }

  const { data, err } = await fetchLocation({ placeId })

  if (err) return err

  const parsedSuggestion = parseSuggestion(data)
  const formattedSuggestion = formatSuggestion(parsedSuggestion)

  return formattedSuggestion
}

export function handleDeliveryAreaLocation(deliveryAreaLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const placeId = deliveryAreaLocation.id

    dispatch(addressLoading())
    dispatch(setAlertTypeVisibility(alertTypes.INVALID_STREET_ADDRESS, false))

    const address: AddressObj = await locationFetcher(placeId)

    dispatch(addressLoaded())

    const isDeliverableAddress = await handleAddressChecks(address, dispatch, 'where_eaze_delivers')
    if (!isDeliverableAddress) return

    dispatch(handleAddressSubmissionChange(address))

    if (address.depot === null || state.location.potentialAddress.depot === null) {
      dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, true))
    }

    if (isLoggedIn(state)) {
      dispatch(fetchCart())
    }
  }
}

// general-purpose function to update an address outside of the address picker interaction
// used to set an address for city pages
export function handleUpdateAddress(placeId) {
  return async (dispatch) => {
    dispatch(addressLoading())
    dispatch(setAlertTypeVisibility(alertTypes.INVALID_STREET_ADDRESS, false))

    const address = await locationFetcher(placeId)

    dispatch(addressLoaded())
    dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, true))
    dispatch(handleAddressSubmissionChange(address))
  }
}

export function fetchPlace(placeId) {
  return function () {
    return locationFetcher(placeId)
  }
}

// This is to allow for reloading of a currently loaded address.
// Used on login/signup so that we can determine payment options by both location and user.
export function fetchCurrentAddress() {
  return (dispatch, getState) => {
    const { location } = getState()
    // execute empty promise if there isn't an active location already
    if (isEmptyObject(location.activeLocation))
      return promisify((cb) => {
        cb()
      })()
    return locationFetcher(location.activeLocation.id).then((address) => {
      dispatch(setActiveLocation(address))
      dispatch(handleAddressIdValidity(address.zip))
      dispatch(setActiveDepot(address.availableDepots))
    })
  }
}

// if we've got a depot mismatch, the user has changed regions. So we display a modal alerting the user.
// or if they are setting an address that is out of our service area
export function setDepotMismatch(value, newDepotID) {
  return (dispatch) => {
    if (newDepotID === null) {
      dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, value))
    } else {
      dispatch(setAlertTypeVisibility(alertTypes.DEPOT_MISMATCH, value))
      dispatch({ type: checkoutAlertTypes.SET_DEPOT_MISMATCH, value })
    }
  }
}

// TODO: MOVE THIS OUT OF LOCATION, this relies on location, but is not location data per se, and it isn't dispatching.
/**
 * Fetches  and returns the rules for a given location based on a user's lat/long
 * @params {integer} latitude
 * @params {integer} longitude
 * @params {(array|string)} types
 * @params {string} origin - Valid origins: EazeWeb (if omitted it will return response from all origins)
 * @returns {object}
 */
export async function fetchRules(latitude, longitude, types, origin) {
  const { data, err } = await getClientSideRules({ latitude, longitude, types, origin })
  if (err) return err
  return data
}

/**
 * Fetches information about an address based on lat/lng, google place ID, eaze place ID, of full address
 * @params {object} address
 * @returns {object}
 **/

export async function fetchAddress(address) {
  const { data, err } = await apiV2.addresses(address)
  if (err) return err
  return data
}

/**
 * adds or removes data set to previousLocations list
 */
export function handleAddressHistoryChange(address) {
  return (dispatch, getState) => {
    const state = getState()
    const { previousLocations } = state.location
    const inSampleLocation = getInSampleLocation(state)
    const addressSaved = previousLocations.some((previousLocation) => previousLocation.id === address.id)

    if (!addressSaved && !inSampleLocation) {
      if (previousLocations.length > 2) {
        dispatch(removePreviousAddress())
      }
      dispatch(setRecentAddress(address))
    }
  }
}

/**
 * @returns boolean for handling a depot mismatch
 */
export function getShouldDepotChange(address) {
  return (dispatch, getState) => {
    const state = getState()
    const cart = getActiveCart(state)
    const previousAddress = getActiveLocation(state)
    const previousDepot = getActiveDepot(state)
    const currentMenuEnabled = getMenuEnabled(state)
    const { menus: hasPreviousAddressMenus } = previousAddress

    const hadPreviousDepot = previousDepot && !isEmptyObject(previousDepot)
    const newDepotId = address.depot && address.depot.id
    const hasChangedDepot = previousDepot.id !== newDepotId

    const cartHasProducts = cart.products && cart.products.length
    return hadPreviousDepot && hasChangedDepot && hasPreviousAddressMenus && cartHasProducts && currentMenuEnabled
  }
}

/*
 * meant to be used along with getShouldDepotChange
 * surfaces a modal to notify user of depot change
 * when items are in cart
 */
export function handleDepotChange(address) {
  return (dispatch) => {
    const addressDepotId = address.depot.id
    dispatch(setDepotMismatch(true, addressDepotId))
    dispatch(savePotentialAddress(address))
  }
}

/*
 * sets location to define what menu is rendered
 * sample, depot specific, etc.
 */
export function handleAddressSubmissionChange(address) {
  return (dispatch) => {
    const { coordinates, exact, id: placeId } = address
    dispatch(savePotentialAddress(address))
    const hasMenu = locationHasMenu(address)
    if (hasMenu) {
      dispatch(setActiveLocation(address))
      dispatch(handleSetAddress(placeId))
      dispatch(isAddressExact(exact))
      dispatch(setCookie({ coordinates, placeId }))
    }
  }
}

export function handleSetAddress(placeId) {
  return async (dispatch) => {
    const address = await fetchAddress({ placeId })
    dispatch(setAddress(address))
  }
}

export function handleAddressIdValidity(zipCode) {
  return async (dispatch) => {
    let isUsaOnly = false
    let isIdScanRequired = false
    if (zipCode) {
      const { err, data } = await fetchIdGeoRegs({ zipcode: zipCode })
      if (err) {
        errHandler(err)
        return
      }
      isUsaOnly = data.usaOnly
      isIdScanRequired = data.identityScanRequired
    }
    dispatch(setIdScanRequired(isIdScanRequired))
    dispatch(setUsaIdOnly(isUsaOnly))
  }
}

export type Address = {
  street?: string
  city: string
  state: string
  zip: string
}

function formatAddressString(address: Address) {
  // addresses will have city, state, and zip but street is optional
  let addressString = `${address.city}, ${address.state} ${address.zip}`
  if (address.street) addressString = `${address.street}, ${addressString}`
  return addressString
}

export async function handleAddressChecks(
  address: Address,
  dispatch: (action) => void,
  entryComponent = ''
): Promise<boolean> {
  let isAddressDeliverable = true
  const addressString = formatAddressString(address)
  const { data: addressData } = await postClientSideAddress({ address: addressString })
  if (addressData) {
    const isNonResidentialAddress = checkResidentialDeliveryOnly(addressData)
    if (isNonResidentialAddress) {
      // For residential-only locations, don't set an address or dismiss the address picker if the address is not a residence
      // Instead let's show an error state
      isAddressDeliverable = false
      dispatch(setResidentialOnlyError(true))
      track('Places.Error', {
        value: addressData.human_address,
        id: addressData.id,
        group: 'suggestions',
        errorType: 'non_residential_address',
        entryComponent
      })
    }
  }
  return isAddressDeliverable
}
